@value blue: #0EA4C9;
@value yellow: #F5F272;
@value green: #6BFC6D;

.statistics {
    display: flex;
    justify-content: space-between;
}
.statistic {
    text-align: center;
}

.low {
    /* fill: blue;
    stroke: #fff; */
}
.high {
    /* fill: green;
    stroke: #fff; */
}
.strength {
    /* fill: yellow;
    stroke:yellow; */
}