.pages {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 15px;
    justify-items: stretch;
}

.intro {
    display: flex;
    /* justify-content: space-between; */
}
.metric {
    /* flex: 1; */
    min-width: 200px;
}
.comment {
    flex: 1;
}
.groups {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 15px;
    justify-items: stretch;
}

.opportunities {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 15px;
    justify-items: stretch;
}

.header {
    margin: 30px 0 20px !important;
}