@value blue: #0EA4C9;
@value yellow: #F5F272;
@value green: #6BFC6D;
@value red: #F2654B;

.annotation {
    margin-bottom: 20px;
    background-color: #fff;
    cursor: pointer;
}

.annotation_active {
    border-left: 4px solid red;
}

.annotation_active_right {
    border-right: 4px solid red;
}
.annotation_link {
    text-decoration: none;
    color: inherit;
}
/* .is_link, .has_location {
} */
.annotation:hover {
    background-color: #F6F7F7;
}

/* .has_location {

} */

.badge {
    background: #666 !important;
}

.badge_low, .badge_high, .badge_strength {
    color: #000 !important;
    margin-right: 5px !important;
    display: inline-block !important;
}
.badge_low {
    background: blue !important;
}
.badge_high {
    background: green !important;
}
.badge_strength {
    background: yellow !important;
}


.meta {
    font-size: 12px;
    color: #666;
    margin: 0;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
}
.id {

}
.title {
    font-size: 18px;
    margin: 10px 0 0;
}


.comment {
    padding: 5px 0 5px 20px;
    border-left: 2px solid #ececec;
    margin-left: 0;
    white-space: pre-line;
    word-break: break-word;
}

.author {
    font-size: 11px;
    text-transform: uppercase;
    color: #666;
    display: block;
    margin-bottom: 10px;
}

.guideline {
    padding: 10px 0;
    white-space: pre-line;
    word-break: break-word;
}

.whole_page_badge {
    margin-left: 5px !important;
    background-color: #666;
    display: inline-block;
}

.wrapper {
    padding: 20px;  
    box-sizing: border-box;

}
.actions {
    composes: wrapper;
    background: #ececec;
}