.wrapper {
    display: flex;
    align-items: flex-start;
}
.list {
    flex: 1;
    position: sticky;
    top: 0px;
    max-height: 100vh;
    overflow-y: auto;
}
.annotations {
    flex: 3;

}