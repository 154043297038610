.wrapper {
    display: flex;
    align-items: flex-start;
}
.list {
    flex: 2;
    padding: 0 15px;
    box-sizing: border-box;
    position: sticky;
    top: 0px;

}
.annotations {
    flex: 2;

    position: sticky;
    top: 0px;
    max-height: 100vh;
    overflow-y: auto;
}
.annotations_container { 
    /* position: sticky; */
}