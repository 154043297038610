@value blue: #0EA4C9;
@value yellow: #F5F272;
@value green: #6BFC6D;
@value red: #F2654B;

.high {
    background-color: green;
}
.low {
    background-color: blue;
}
.strength {
    background-color: yellow;
}
@keyframes slidein {
    from { transform: scale(2); }
    to   { transform: scale(1); }
  }
.bullet {
    position: absolute;
    height: 20px;
    width: 20px;
    border-radius: 15px;
    cursor: pointer;
    box-sizing: border-box;
    margin-top: -8px;
    margin-left: -8px;
    box-shadow: 1px 0px 2px rgba(0, 0, 0, .25);

    animation-name: slidein;
    animation-duration: 400ms;
    animation-timing-function: ease-in;
}
.bullet:hover {
    box-shadow: 3px 2px 5px rgba(0, 0, 0, .45);
}
.bullet_active {
    background-color: red;
    z-index: 3;
    animation-name: slidein;
    animation-duration: 400ms;
    animation-timing-function: ease-in;

}

.award {
    height: 16px;
    width: 16px;
    padding: 2px 0 0 3px;
}
