

.opportunity {
    text-decoration: none;
    padding-right: 15px;
    padding-bottom: 15px;
}

.opportunity_link {
    padding: 15px 40px 15px 15px;
    box-sizing: border-box;

    display: block;
    min-height: 190px;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: space-between; */
}
