


.wrapper {
    display: flex;
    align-items: flex-start;
    position: relative;
}

.image_container {
    min-width: 50%;
    width: 50%;
}

.annotations {
    padding: 1px 1px 1px 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    overflow-y: scroll;
    max-height: 100vh;
    box-sizing: border-box;
    width: 100%

}




.bullet_wrapper {
    position: relative;
}

.annotations_wrapper {
}


.image {
    display: block;
    width: 100%;


    background-image: linear-gradient(45deg, #eee 25%, transparent 25%), linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 75%), linear-gradient(-45deg, transparent 75%, #eee 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;


}



.tabs {
    margin-bottom: 10px;
}