.page {
    text-decoration: none;
    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.page_link {
    box-sizing: border-box;
    display: block;
    min-height: 190px;
}

.inlineWrapper {
    padding: 15px 40px 15px 15px;

}

.image {
    background-size: cover;
    width: 100%;
    height: 100px;
    border-radius: 1rem 1rem 0 0;
    justify-self: stretch;
    background-color: #ccc;
}

.img {
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%), linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 75%), linear-gradient(-45deg, transparent 75%, #eee 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

}