

.bullet_wrapper {
    position: relative;
}
.image {
    display: block;
    width: 100%;
    background-image: linear-gradient(45deg, #eee 25%, transparent 25%), linear-gradient(-45deg, #eee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eee 75%), linear-gradient(-45deg, transparent 75%, #eee 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;

}

.wrapper {
    max-height: 100vh;
    top: 10px;
    position: sticky;
}